import type TableConfig from '../../lib/types/tableConfigTypes'
import type { SystemCssProperties } from '@mui/system'
import type { TableConfigField } from '../../lib/types/tableConfigTypes'
import React, { useMemo } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useQueries } from '@tanstack/react-query'
import { useSearchParams } from 'next/navigation'
import Table from '../Table'
import { formatDateString, formatSeason, isAfter, money } from '../../lib/utils/formatters'
import Legend from '../Legend'
import {
    useGetBoardPlayer,
    usePlayerSummary,
    useGetRosterLikelihood,
    useGetStrategyLOC,
    useGetPlayerById,
    useDepthChartList,
    getDepthChartItem,
    usePlayer,
    useLegendColors,
} from '../../lib/hooks'
import { useConstantsContext } from '../../lib/contexts/ConstantsContext'
import PersonalField from '../personal/PersonalField'
import { isNumber, asNumber, safeRatio, formatNumber } from '../../lib/utils/math'
import SummaryStat from '../SummaryStat'
import PermissionContent from '../wrappers/PermissionContent'
import PermissionFeature from '../wrappers/PermissionFeature'
import { useGetDesignatedBoardId } from '@/lib/hooks/useBoard'

type ContractDetailsDialogProps = {
    contract: DTO.PlayerContractDetails | undefined
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<string | undefined>>
    isLoading: boolean
}

type SeasonSalaryKeys = keyof DTO.PlayerContractSalary
type SeasonSalariesTable = { key: SeasonSalaryKeys; title: string }
type SeasonSalariesTableConfig = SeasonSalariesTable[]

const borderColors = { borderRightColor: 'divider', borderLeftColor: 'divider', borderTopColor: 'divider' }
const cellFormat: SystemCssProperties = {
    textAlign: 'center',
    width: 150,
    borderRight: '1px solid',
    ...borderColors,
}

const tableRows: SeasonSalariesTableConfig = [
    { key: 'signingAge', title: 'Age' },
    { key: 'capAmount', title: 'Cap Amount' },
    { key: 'capPercent', title: 'Cap Percent' },
    { key: 'taxAmount', title: 'Tax Amount' },
    { key: 'apronTaxAmount', title: 'Apron Tax Salary' },
    { key: 'effectiveProtectionAmount', title: 'Effective Protection Amount' },
    { key: 'signingProtectionAmount', title: 'Protection Amount @ Signing' },
    { key: 'option', title: 'Option Details' },
    { key: 'optionDecision', title: 'Option Decision' },
    { key: 'likelyBonus', title: 'Likely Incentives' },
    { key: 'unlikelyBonus', title: 'Unlikely Incentives' },
]

export const getContractStyles = (
    key: keyof DTO.PlayerContractSalary,
    ss: Partial<DTO.PlayerContractSalary>,
    salaryYear: number,
    contractType: Enum.ContractType | null,
    salaryGuaranteeDate: string,
    contractId: string | null
): SystemCssProperties => {
    const { [key]: value } = ss
    let styles: SystemCssProperties =
        value === 0 || asNumber(value) ? { ...cellFormat, textAlign: 'right' } : { ...cellFormat }

    if (key === 'capAmount' && contractType !== '10D' && contractType !== '2W') {
        if (ss.option === 'Team Opt' && !ss.optionExercised) {
            styles = { ...styles, backgroundColor: '#70ad47', color: 'white' }
        } else if (
            !!ss.salaryYear &&
            !!ss.overallProtectionCoverage &&
            ss.overallProtectionCoverage !== 'Full' &&
            !ss.optionExercised &&
            !isAfter(
                new Date(salaryGuaranteeDate).setFullYear(
                    new Date(salaryGuaranteeDate).getFullYear() + ss.salaryYear - salaryYear
                )
            )
        ) {
            styles = { ...styles, backgroundColor: '#fdc002' }
        } else if (ss.salaryYear && ss.salaryYear < salaryYear) {
            styles = { ...styles, backgroundColor: 'divider' }
        } else if (ss.salaryYear && ss.salaryYear > salaryYear && contractId !== ss.contractId) {
            styles = { ...styles, fontStyle: 'italic' }
        } else if (ss.option === 'Early Termination Opt') {
            styles = { ...styles, backgroundColor: '#8ea8db' }
        } else if (ss.option === 'Player Opt' && !ss.optionExercised) {
            styles = { ...styles, backgroundColor: '#106bb6', color: 'white' }
        }
    }
    if (
        ss.optionDeclined &&
        key !== 'salaryYear' &&
        key !== 'signingAge' &&
        key !== 'optionDecision' &&
        key !== 'option'
    ) {
        styles = { ...styles, textDecoration: 'line-through' }
    }

    return styles
}

const tableConfig = (
    contract: DTO.PlayerContractDetails | undefined,
    salaryYear: number,
    salaryGuaranteeDate: string,
    maxContractLength: number
): TableConfig<SeasonSalariesTable> => {
    const fields: TableConfigField<SeasonSalariesTable>[] = [
        {
            key: 'title',
            label: '',
            select: (r) => r.title,
            headCellFormat: () => ({
                textAlign: 'center',
                borderLeft: '1px solid',
                borderRight: '1px solid',
                borderTop: '1px solid',
                ...borderColors,
            }),
            format: {
                borderLeft: '1px solid',
                borderRight: '1px solid',
                fontWeight: 'medium',
                ...borderColors,
            },
            skeletonStyle: { xs: 50 },
        },
    ]
    if (contract?.seasonSalaries.length) {
        contract.seasonSalaries.forEach((ss, i) => {
            fields.push({
                key: `salaryYear-${i}`,
                label: ss.salaryYear ? `${formatSeason(ss.salaryYear, true)} Sal.` : '',
                select: ({ key }) => {
                    const { [key]: value } = ss
                    if (key === 'signingAge') return value
                    if (key === 'capPercent') return formatNumber(Number(value), 1, 'percent', 0)
                    if (key === 'capAmount' && (contract.contractType === '10D' || contract.contractType === '2W'))
                        return contract.contractType
                    if (value === 0 || asNumber(value)) return money(Number(value))

                    return value
                },
                headCellFormat: () => ({
                    textAlign: 'center',
                    borderRight: '1px solid',
                    borderTop: '1px solid',
                    ...borderColors,
                }),
                format: ({ key }) =>
                    getContractStyles(
                        key,
                        ss,
                        salaryYear,
                        contract.contractType,
                        salaryGuaranteeDate,
                        contract.contractId
                    ),
                skeletonStyle: { xs: 50 },
            })
        })
        new Array(maxContractLength - contract.seasonSalaries.length).fill(undefined).forEach((_, i) => {
            fields.push({
                key: `emptyYear-${i}`,
                label: '',
                headCellFormat: () => ({
                    textAlign: 'center',
                    borderRight: '1px solid',
                    borderTop: '1px solid',
                    ...borderColors,
                }),
                format: () => cellFormat,
            })
        })
        if (contract.totalSalaries) {
            fields.push({
                key: 'total',
                label: 'Total',
                select: ({ key }) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const { [key]: value } = contract.totalSalaries!
                    if (key === 'capPercent') {
                        if (isNumber(value)) {
                            const avgDenominator = contract.seasonSalaries.reduce(
                                (acc, ss) => acc + (ss.optionDeclined ? 0 : 1),
                                0
                            )
                            const capPercentAvg = safeRatio(value, avgDenominator)
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            return `${formatNumber(capPercentAvg!, 1, 'percent', 0)!}(Avg.)`
                        }
                    } else if (value === 0 || asNumber(value)) return money(Number(value))

                    return value
                },
                headCellFormat: () => ({
                    textAlign: 'center',
                    borderRight: '1px solid',
                    borderTop: '1px solid',
                    ...borderColors,
                }),
                format: ({ key }) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const { [key]: value } = contract.totalSalaries!
                    if (value === 0 || asNumber(value)) return { ...cellFormat, textAlign: 'right' }
                    return { ...cellFormat }
                },
            })
        }
    }
    return {
        fields,
        loadingSkeleton: {
            numOfRows: 10,
            height: 200,
        },
    }
}

const ContractDetailsDialog = ({ contract, open, setOpen, isLoading }: ContractDetailsDialogProps): JSX.Element => {
    const { salaryYear, salaryGuaranteeDate } = useConstantsContext()
    const searchParams = useSearchParams()
    const playerSlug = searchParams.get('playerid')! // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const { data: playerData } = usePlayer(playerSlug)
    let maxContractLength = 5
    if (contract?.signingDate && new Date(contract.signingDate).getFullYear() < 2011) {
        maxContractLength = 6
    }
    // Since the 2011 CBA, Bird-exception contracts can be up to five years in length, down from six under the previous CBA.

    const contractDetailsTableConfig = useMemo(
        () => tableConfig(contract, salaryYear, salaryGuaranteeDate, maxContractLength),
        [contract, salaryYear, salaryGuaranteeDate, maxContractLength]
    )
    const [, salaryColors] = useLegendColors()

    const { data: summary, isLoading: isSummaryLoading } = usePlayerSummary({
        playerId: contract?.playerId,
        isGLeaguePlayer: playerData?.isGLeaguePlayer || playerData?.isSLPlayer ? 'true' : 'false',
    })

    const { data: primaryBoardId, isLoading: isPrimaryBoardLoading } = useGetDesignatedBoardId(
        'PRO_TRADE_TIERS',
        salaryYear,
        {
            enabled: contract?.playerLevel === 'PRO',
        }
    )

    const { data: boardPlayer, isInitialLoading: isBoardPlayerLoading } = useGetBoardPlayer(
        primaryBoardId ? primaryBoardId.boardId : undefined,
        contract?.playerId,
        { enabled: contract?.playerLevel === 'PRO' && !!contract.isActive }
    )
    const { data: player, isLoading: isPlayerLoading } = useGetPlayerById(contract?.playerId)
    const { data: rosterLikelihood, isInitialLoading: isRosterLikelihoodLoading } = useGetRosterLikelihood({
        playerId: contract?.playerId,
        teamId: player?.teamId,
        contractId: contract?.contractId,
    })

    const { data: strategyLOC, isLoading: isStrategyLOCLoading } = useGetStrategyLOC({
        playerId: contract?.playerId,
        contractId: contract?.contractId,
    })

    const { data: depthCharts, isLoading: isLoadingDepthCharts } = useDepthChartList(true)

    const depthChartItems = useQueries({
        queries:
            depthCharts
                ?.filter(
                    (dc) =>
                        (dc.designation === 'NBA TNDC' || dc.designation === 'NBA PTNDC') && dc.season === salaryYear
                )
                .map(({ depthChartId }) => ({
                    queryKey: ['depth-charts', depthChartId, contract?.playerId],
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    queryFn: () => getDepthChartItem(depthChartId, contract!.playerId),
                    enabled: !!depthChartId && !!contract?.playerId,
                })) ?? [],
    })
    const isDepthChartItemsLoading = depthChartItems.some((dc) => dc.isLoading)

    const depthChartItemsByDesignation = useMemo(() => {
        const depthChartsById = depthCharts?.reduce<Record<string, Enum.DepthChartDesignation>>((acc, dc) => {
            if (dc.designation) acc[dc.depthChartId] = dc.designation
            return acc
        }, {})
        return depthChartItems
            .filter((dc) => dc.data)
            .reduce<Record<Enum.DepthChartDesignation, string>>(
                (acc, { data }) => {
                    if (data && 'depthChartId' in data && depthChartsById) {
                        acc[depthChartsById[data.depthChartId]] = `${data.position}${data.rank}`
                    }
                    return acc
                },
                { 'NBA TNDC': '', 'NBA PTNDC': '', 'GLG TNDC': '', 'GLG PTNDC': '', 'Summer League': '' }
            )
    }, [depthCharts, depthChartItems])

    const playerSummary = useMemo(() => {
        const res: DTO.PlayerSummary[] | undefined = []
        if (summary?.length) {
            const s = summary.filter((ps) => ps.label !== 'Intensity Grade')
            res.push(...s)
        }
        if (!!strategyLOC && strategyLOC.locNow) {
            res.push({ label: 'Strategy Now', value: strategyLOC.locNow })
        }
        return res
    }, [summary, strategyLOC])

    if (!isLoading && !contract) {
        return (
            <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(undefined)}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingY: 1,
                    }}
                >
                    Contract Details
                    <IconButton aria-label="close" onClick={() => setOpen(undefined)} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>No Contract Found</Typography>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(undefined)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingY: 1,
                    }}
                >
                    Contract Details
                    <IconButton aria-label="close" onClick={() => setOpen(undefined)} size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column">
                        <Grid container rowSpacing={2} alignItems="flex-start">
                            <Grid container item xs={12} md={4}>
                                <Grid container item rowSpacing={1}>
                                    <PersonalField
                                        valueColor="text.primary"
                                        isLoading={isLoading}
                                        label="Player"
                                        value={contract?.playerName}
                                        labelColumns={{ xs: 8, md: 4 }}
                                        valueColumns={{ xs: 4, md: 8 }}
                                    />
                                    <PersonalField
                                        valueColor="text.primary"
                                        isLoading={isLoading}
                                        label="Team"
                                        value={contract?.teamName}
                                        labelColumns={{ xs: 8, md: 4 }}
                                        valueColumns={{ xs: 4, md: 8 }}
                                    />
                                    <PersonalField
                                        valueColor="text.primary"
                                        isLoading={isLoading}
                                        label="Agent"
                                        value={contract?.agentName || 'N/A'}
                                        labelColumns={{ xs: 8, md: 4 }}
                                        valueColumns={{ xs: 4, md: 8 }}
                                    />
                                    <PersonalField
                                        valueColor="text.primary"
                                        isLoading={isLoading}
                                        label="Agency"
                                        value={contract?.agencyName || 'N/A'}
                                        labelColumns={{ xs: 8, md: 4 }}
                                        valueColumns={{ xs: 4, md: 8 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={4}>
                                <Grid container item rowSpacing={1}>
                                    <PermissionContent type="roster-likelihood">
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isRosterLikelihoodLoading || isPlayerLoading || isLoading}
                                            label="Likelihood on Roster"
                                            value={
                                                !!rosterLikelihood && rosterLikelihood.rosterLikelihood ? (
                                                    <Typography
                                                        component="span"
                                                        display="flex"
                                                        alignItems="baseline"
                                                        gap={0.5}
                                                    >
                                                        {rosterLikelihood.rosterLikelihood.toString()}%
                                                        <Typography component="span" fontSize={14}>
                                                            ({rosterLikelihood.season})
                                                        </Typography>
                                                    </Typography>
                                                ) : (
                                                    'N/A'
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 6 }}
                                            valueColumns={{ xs: 4, md: 6 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isRosterLikelihoodLoading || isPlayerLoading || isLoading}
                                            label="Likelihood in League"
                                            value={
                                                !!rosterLikelihood && rosterLikelihood.leagueLikelihood ? (
                                                    <Typography
                                                        component="span"
                                                        display="flex"
                                                        alignItems="baseline"
                                                        gap={0.5}
                                                    >
                                                        {rosterLikelihood.leagueLikelihood.toString()}%
                                                        <Typography component="span" fontSize={14}>
                                                            ({rosterLikelihood.season})
                                                        </Typography>
                                                    </Typography>
                                                ) : (
                                                    'N/A'
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 6 }}
                                            valueColumns={{ xs: 4, md: 6 }}
                                        />
                                    </PermissionContent>
                                    {contract?.isActive && (
                                        <>
                                            <PermissionFeature type="custom-boards">
                                                <PersonalField
                                                    valueColor="text.primary"
                                                    isLoading={
                                                        isLoading || isBoardPlayerLoading || isPrimaryBoardLoading
                                                    }
                                                    label="Trade Block Tier"
                                                    value={!!boardPlayer && boardPlayer.tier ? boardPlayer.tier : 'N/A'}
                                                    labelColumns={{ xs: 8, md: 6 }}
                                                    valueColumns={{ xs: 4, md: 6 }}
                                                />
                                            </PermissionFeature>
                                            <PermissionFeature type="depth-charts">
                                                <PersonalField
                                                    valueColor="text.primary"
                                                    isLoading={
                                                        isLoading || isDepthChartItemsLoading || isLoadingDepthCharts
                                                    }
                                                    label="TNDC"
                                                    value={depthChartItemsByDesignation['NBA TNDC']}
                                                    labelColumns={{ xs: 8, md: 6 }}
                                                    valueColumns={{ xs: 4, md: 6 }}
                                                />
                                                <PersonalField
                                                    valueColor="text.primary"
                                                    isLoading={
                                                        isLoading || isDepthChartItemsLoading || isLoadingDepthCharts
                                                    }
                                                    label="Proj. TNDC"
                                                    value={depthChartItemsByDesignation['NBA PTNDC']}
                                                    labelColumns={{ xs: 8, md: 6 }}
                                                    valueColumns={{ xs: 4, md: 6 }}
                                                />
                                            </PermissionFeature>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={4}>
                                <Grid container item spacing={1} justifyContent="flex-end" xs={12} sm={4} md={12}>
                                    {isSummaryLoading || isStrategyLOCLoading
                                        ? [1, 2, 3, 4].map((d) => (
                                              <Grid key={d} item xs={12} md={6}>
                                                  <SummaryStat key={d} label="" value={null} isLoading />
                                              </Grid>
                                          ))
                                        : playerSummary.map((d) => (
                                              <Grid key={d.label} item xs={12} md={6}>
                                                  <SummaryStat label={d.label} value={d.value} isLoading={false} />
                                              </Grid>
                                          ))}
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} marginTop={2}>
                                {contract?.seasonSalaries.length || isLoading ? (
                                    <>
                                        <Table<SeasonSalariesTable>
                                            rows={tableRows}
                                            config={contractDetailsTableConfig}
                                            isLoading={isLoading}
                                            getRowKey={(r) => `${r.key}`}
                                        />
                                        <Legend title="Salary Details" colors={salaryColors} itemGap />
                                    </>
                                ) : (
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 250,
                                        }}
                                    >
                                        <Typography variant="h6" color="text.secondary">
                                            No salary details available
                                        </Typography>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid container item justifyContent="space-between" marginTop={2}>
                                <Grid container item xs={12} md={7} rowSpacing={4}>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Contract ID"
                                            value={contract?.dataSourceId}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Contract Status"
                                            value={contract?.recordStatus}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Contract Comments"
                                            value={contract?.contractComments ? contract.contractComments : 0}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Salary Comments"
                                            value={contract?.salaryComments ? contract.salaryComments : 0}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Protection Conditions"
                                            value={contract?.protectionConditions ? contract.protectionConditions : 0}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Protection Comments"
                                            value={contract?.protectionComments ? contract.protectionComments : 0}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Trade Restriction Title"
                                            value={contract?.tradeRestriction}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Trade Restriction End Date"
                                            value={formatDateString(
                                                contract?.tradeRestrictionEndDate,
                                                'YYYY-MM-DD',
                                                'utc'
                                            )}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Trade Kicker"
                                            value={contract?.tradeKicker ? `${contract.tradeKicker}%` : null}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Trade Kicker Earned Flag"
                                            value={
                                                contract?.hasEarnedTradeKicker ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Player Consent for Trade"
                                            value={contract?.tradeConsent}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Contract Type"
                                            value={contract?.contractTypeDetail || contract?.contractType}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Signed Method"
                                            value={contract?.signedMethod}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Free Agent Designation"
                                            value={contract?.freeAgentDesignation}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Free Agent Status"
                                            value={contract?.freeAgentStatus}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="No Trade Flag"
                                            value={
                                                contract?.isNoTrade ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Designated Rookie Scale Ext"
                                            value={
                                                contract?.isRookieScaleExtension ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Designated Vet. Cont."
                                            value={
                                                contract?.isVeteranContract ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Designated Vet. Ext."
                                            value={
                                                contract?.isVeteranExtension ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Poison Pill Flag"
                                            value={
                                                contract?.poisonPillAmount && contract.poisonPillAmount > 0 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Poison Pill Amount"
                                            value={contract?.poisonPillAmount ? contract.poisonPillAmount : 0}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Minimum Contract"
                                            value={contract?.minContract}
                                            labelColumns={{ xs: 8, md: 4 }}
                                            valueColumns={{ xs: 4, md: 8 }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} md={2} alignItems="flex-start">
                                    <Grid container item rowSpacing={1}>
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 1"
                                            value={
                                                contract?.exhibit1 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 1a"
                                            value={
                                                contract?.exhibit1a ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 1b"
                                            value={
                                                contract?.exhibit1b ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 2"
                                            value={
                                                contract?.exhibit2 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 3"
                                            value={
                                                contract?.exhibit3 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 4"
                                            value={
                                                contract?.exhibit4 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 5"
                                            value={
                                                contract?.exhibit5 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 6"
                                            value={
                                                contract?.exhibit6 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 7"
                                            value={
                                                contract?.exhibit7 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 8"
                                            value={
                                                contract?.exhibit8 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 9"
                                            value={
                                                contract?.exhibit9 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 10"
                                            value={
                                                contract?.exhibit10 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 10 Bon Amt"
                                            value={contract?.exhibit10BonusAmount ? contract.exhibit10BonusAmount : 0}
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit 10 Bon Earn"
                                            value={contract?.exhibit10BonusEarned ? contract.exhibit10BonusEarned : 0}
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit Comments"
                                            value={contract?.exhibitComments ? contract.exhibitComments : 0}
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                        <PersonalField
                                            valueColor="text.primary"
                                            isLoading={isLoading}
                                            label="Exhibit Par13"
                                            value={
                                                contract?.exhibitPar13 ? (
                                                    <CheckIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )
                                            }
                                            labelColumns={{ xs: 8, md: 10 }}
                                            valueColumns={{ xs: 4, md: 2 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    )
}

// do not put dialog (and hooks) in the JSX tree if the user does not have permission to view contracts
const ContractDetailsDialogWrapper = (props: ContractDetailsDialogProps): JSX.Element => (
    <PermissionFeature type="contracts">
        <ContractDetailsDialog {...props} />
    </PermissionFeature>
)

export default ContractDetailsDialogWrapper
